import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'

class ProjectTemplate extends React.Component {
  render() {

    const project = get(this.props, 'data.contentfulProject')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    console.log(`project: `, project)

    return (
      <div style={{ background: '#fff' }}>
        <Helmet title={`${project.name} | ${siteTitle}`} />
        <div className="wrapper">
          Project: {project.name}
        </div>
      </div>
    )
  }
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulProject(slug: { eq: $slug }) {
      name
      slug
      url
      description
      gif {
        file {
          url
        }
        title
      }
      screenshot {
        file {
          url
        }
        title
      }
      tags
    }
  }
`
